
body, html, #root, .App {
  padding: 0px;
  margin: 0px;
  height: 100%;
}

.App {
  --default-spacing: 16px;
  --default-big-spacing: 32px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}


.content {
  padding: var(--default-big-spacing);
  padding-top: 0px;
  flex: 1;
  height: calc(100% - 44px);
}

.content.legal {
  overflow-y: auto;
  padding-top: var(--default-spacing);
}

.footer {
  flex: 0 1 44px;
  padding: 4px var(--default-big-spacing);
  line-height: 44px;
  color: #888888;
  background-color: #eeeeee;
  display: flex;
  flex-direction: row;
  justify-items: stretch;
}


.header {
  flex: 0 1 auto;
  padding: var(--default-big-spacing) var(--default-big-spacing) var(--default-spacing) var(--default-big-spacing);
  margin-bottom: var(--default-big-spacing);
  background-color: #eeeeee;
}

.header .subtitle {
  color: #888888;
}

.footer .item {
  flex: 1;
}

.footer .item.center {
  text-align: center;
}
.footer .item.right {
  text-align: right;
}


.card {
  margin-bottom: var(--default-big-spacing);
  box-shadow: 1px 1px 8px rgba(0,0,0,0.2);
}

.question-title {
  font-size: 18px;
  font-weight: bold;
}

.question .card-content {
  margin-top: 24px;
}

.question input[type='radio'],
.question input[type='checkbox'] {
  margin-right: 8px;
}

.question input[type='text'] {
  margin-left: 8px;
}

.question textarea {
  min-height: 100px;
  width: 100%;
}


.progressWrapper {
  margin: 0px 0px var(--default-big-spacing) 0px;
}

.result {
  color: blue;
  font-weight: 800;
}

.questionnaire {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.questionnaire .q-header {
  flex: 0 1 100px;
}

.questionnaire .questions {
  flex: 1 1 auto;
  position: relative;
}

.questionnaire .question-wrapper .card {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.questionnaire .question-wrapper .card-title {
  margin-bottom: var(--default-big-spacing);
}

.questionnaire .buttons {
  flex: 0 1 30px;
  text-align: center;
}

.legal_intro { margin-bottom: var(--default-spacing) }
.legal_paragraph { margin-bottom: var(--default-spacing) }
.legal_title { font-size: 20px; font-weight: bold; margin-bottom: 4px }
.legal_section { margin-bottom: var(--default-spacing); line-height: 1.3em }
ul.legal-list li {
    list-style: circle;
    margin-left: 18px;
}


@media (max-width: 480px) {
  .App {
    --default-spacing: 8px;
    --default-big-spacing: 12px
  }

  body {
    font-size: 14px;
  }

  h3 {
    font-size: 1.25rem;
  }
  h4 {
    font-size: 1.2rem;
  }

  .footer {
    flex: 0 1 32px;
    height: 24px;
    line-height: 24px;
    font-size: 0.75em;
  }

  .btn {
    font-size: 12px;
  }

  hr {
    margin: 8px;
  }

  .questionnaire .q-header {
    flex: 0 1 60px;
  }

  .questionnaire .card-content {
    font-size: 16px;
  }
}